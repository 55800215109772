import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  constructor(private translateService: TranslateService) {}

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts = [];
  }

  showError(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      ...options,
      ...{ className: 'border-danger bg-light-red text-dark-gray mt-1' },
    });
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      ...options,
      ...{ className: 'border-success bg-light-green text-dark-gray mt-1' },
    });
  }

  showWarning(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show(textOrTpl, {
      ...options,
      ...{ className: 'border-warning bg-light-yellow text-black mt-1' },
    });
  }
}
