import { ImageSliderDataInterface } from './../shared/interfaces/image-slider-data.interface';
import { MarketGroupingUnitTypes } from '@app/enums/market-grouping-unit-types.enum';
import { MarketUnitTypes } from '@app/enums/market-unit-types.enum';
import { RequestUnitStatus } from '@app/enums/request-unit-status.enum';
import { Region, City, MarketRequest } from '.';
import { MarketUnit } from './market-unit';
import { UnitStatistic } from './unit-statistic';
import { MARKET_UNIT_CONSTANTS } from '@app/helpers/market-unit-constants';
import { FileData } from '@app/interfaces/file-data.interface';

export class GroupUnit {
  relationships = {
    market_units: { rel: 'has_many', model: MarketUnit },
    published_market_units: { rel: 'has_many', model: MarketUnit },
    base_requests: { rel: 'has_many', model: MarketRequest },
  };

  constructor(
    public id?: number,
    public group_unit_code?: number,
    public thumbnail_url?: any,
    public description?: string,
    public region_id?: number,
    public city_id?: number,
    public owner_mobile?: string,
    public owner_communication_type?: {
      whatsapp: boolean;
      mobile_number: boolean;
    },
    public group_unit_type?: MarketGroupingUnitTypes,
    public request_status?: RequestUnitStatus,
    public units_information?: { [key in MarketUnitTypes]: number },
    public market_units?: MarketUnit[],
    public editable?: boolean,
    public available_unit_types?: MarketUnitTypes[],
    public published_market_units?: MarketUnit[],
    public region_obj?: Region,
    public city_obj?: City,
    public unit_area?: number,
    public unit_price?: number,
    public units_statistic_data?: UnitStatistic,
    public published_by?: string,
    public subsidizable?: boolean,
    public sort_value?: any,
    public published_actor?: string,
    public marketplace_name?: string,
    public publish_date?: string,
    public district?: string,
    public views_count?: number,
    public min_price?: number,
    public min_area?: number,
    public max_area?: number,
    public media_images?: any[],
    public region_name?: string,
    public city_name?: string,
    public amonity?: boolean,
    public district_id?: number,
    public anonymity?: boolean,
    public exterior_photo?: FileData,
    public exterior_photos?: FileData[],
    public tags?: any[],
    public project_code?: string,
    public company_cr_number?: string,
    public is_favorite?: boolean,
    public type?: string,
    public resource_type?: string,
    public resource_id?: number,
    public nhc_related?: boolean,
    public base_requests?: MarketRequest[],
    public from_unit_marketing_request?: boolean
  ) {}

  get detailUrl() {
    return `/grouping-unit/${this.id}`;
  }

  get imageGallery(): ImageSliderDataInterface[] {
    const groupImage: ImageSliderDataInterface = {
      sourceUrl: this.thumbnail_url,
    };
    return [groupImage];
  }

  get availableUnitCount() {
    return this.units_statistic_data?.available_units_count;
  }

  get published_by_owner(): boolean {
    return this.published_actor === MARKET_UNIT_CONSTANTS.publishedActor.owner;
  }

  get published_by_broker(): boolean {
    return this.published_actor === MARKET_UNIT_CONSTANTS.publishedActor.broker;
  }

  get published_by_company_owned(): boolean {
    return this.published_actor === MARKET_UNIT_CONSTANTS.publishedActor.company_owned;
  }

  get deedNumbers(): Array<string | number> {
    if (!this.market_units) {
      return [];
    }
    return this.market_units
      ?.map((marketUnit) => (marketUnit?.deed_number ? marketUnit.deed_number : ''))
      .filter((deed) => deed);
  }

  get sellerInfoTitleKey(): string | undefined {
    if (this.published_by_owner) {
      return 'MARKET_UNIT.OWNER_INFO_TITLE';
    } else if (this.published_by_broker) {
      return 'MARKET_UNIT.BROKER_INFO_TITLE';
    } else if (this.published_by_company_owned) {
      return 'MARKET_UNIT.COMPANY_OWNED_INFO_TITLE';
    } else {
      return;
    }
  }
  get activeRequest() {
    return this.base_requests && this.base_requests.length > 0 ? this.base_requests[0] : null;
  }
}
