import { formatDate, formatNumber } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  AuctionStatus,
  EsriMapPolygonStatus,
  ProductTypes,
  ProductTypeTexts,
  ProjectTypes,
  UnitType,
} from '@app/enums';
import { VoucherCodeType } from '@app/enums/voucher-type.enum';
import { FilterInterface } from '@app/interfaces';
import { Beneficiary, City, Notification, Project, ProjectUnit, Region, RuleResult } from '@app/models';
import { Promotion } from '@app/models/promotion';
import { ImageSliderDataInterface, ImageThumbGalleryDataInterface } from '@app/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '.';
import { currentLang } from './translation-utils';
import * as moment from 'moment';
import { LocationData } from '@app/models/location-data';
import * as GeoJSON from 'geojson';

export const isObject = (object: any) => {
  return typeof object === 'object';
};

export const currentDirection = () => {
  return localStorage.getItem(Constants.LOCAL_STORAGE_LANGUAGE) &&
    localStorage.getItem(Constants.LOCAL_STORAGE_LANGUAGE) !== Constants.DEFAULT_LANGUAGE
    ? Constants.REVERSE_DIRECTION
    : Constants.DEFAULT_DIRECTION;
};

export const filterAttributes = (currentFilter: any = {}, arrayType: 'join' | 'separate' = 'join') => {
  fromObjectToObjectQueryParams(currentFilter);

  const attributes: any[] = Object.keys(currentFilter)
    .map((key: any) => {
      if (currentFilter[key] !== undefined) {
        const filterAttribute: FilterInterface = {
          attr: key.replace('.', ']['),
          value: !Array.isArray(currentFilter[key])
            ? isObject(currentFilter[key])
              ? currentFilter[key]?.value || currentFilter[key]
              : currentFilter[key]
            : arrayType === 'join'
            ? (currentFilter[key] || []).join(',')
            : currentFilter[key] || [],
          opt: currentFilter[key]?.opt,
        };
        return filterAttribute;
      }
      return null;
    })
    .filter((el) => {
      return el?.value;
    });

  return attributes;
};

export const getMappedKeyValue = (parentKey: string, value: string) => {
  return createMappedKeyValueTranslation(parentKey, value);
};

export const createMappedKeyValueTranslation = (parentKey: string, value: string) => {
  return value ? parentKey + '.' + value.toString().toUpperCase() : '';
};

export const generateArrayIndexes = (count: number): Array<number> => {
  const indexes = [];
  for (let i = 0; i < count; i++) {
    indexes.push(i);
  }
  return indexes;
};

export const getAvailableDescription = (
  translate: any,
  availableUnits: number | undefined,
  project_type = 'LANDING',
  isSoldOut = false,
  isFullyBooked = false
) => {
  const translateKey = `PROJECT_CARD.${project_type}.`;
  if (availableUnits == null) {
    return null;
  }
  switch (true) {
    case availableUnits === 0 && isSoldOut:
      return {
        msg: 'PROJECT_CARD.SOLD_OUT',
      };
    case availableUnits === 0 && isFullyBooked:
      return {
        msg: 'PROJECT_CARD.FULLY_BOOKED',
      };
    case availableUnits >= 1 && availableUnits < 10:
      return {
        msg: translate.instant(translateKey + 'AVAILABLE_PLOT', {
          available: availableUnits,
        }),
      };
    case availableUnits >= 10 && availableUnits < 50:
      return {
        msg: translate.instant(translateKey + 'AVAILABLE_PLOT', {
          available: availableUnits,
        }),
      };
    case availableUnits >= 50 && availableUnits < 100:
      return {
        msg: translate.instant(translateKey + 'AVAILABLE_PLOT', {
          available: availableUnits,
        }),
      };
    case availableUnits >= 100 && availableUnits < 200:
      return {
        msg: translate.instant(translateKey + 'MORE_THAN_100_AVAILABLE_PLOT', {
          available: availableUnits,
        }),
      };
    case availableUnits >= 200:
      return {
        msg: translate.instant(translateKey + 'MORE_THAN_200_AVAILABLE_PLOT', {
          available: availableUnits,
        }),
      };
    default:
      return null;
  }
};

// Using Haversine formula, source of the code: https://www.geodatasource.com
export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string = 'K'
): number => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const locationName = (currentLang: string, obj: Region | City) => {
  if (!obj) {
    return '';
  }
  return currentLang === Constants.EN ? obj['name_en']! : obj['name_ar']!;
};

export const arrowIconClassByLanguage = (currentLang: string) => {
  return currentLang === Constants.EN ? 'icon-arrow-right' : 'icon-arrow-left';
};

export const groupOfUnitTypes = (types: string[], translate: any) => {
  const group = types ? types?.map((t) => translate.instant(getMappedKeyValue('PROJECT_CARD.UNIT_TYPES', t))) : [];
  return group;
};

export const getNumberOfImages = (images?: ImageSliderDataInterface[], targetIndex: number = 10) => {
  // return new array with a number of images from less than target index (default = 10)
  return images && images.filter((image, index) => index < targetIndex);
};

export const randomString = (textToAppend?: string, textToPrepend?: string, delimiter?: string) => {
  const rString = (Math.random() + 1).toString(36).substr(2);
  delimiter = delimiter || '-';
  textToAppend = textToAppend ? textToAppend + delimiter : '';
  textToPrepend = textToPrepend ? delimiter + textToPrepend : '';

  return `${textToAppend}${rString}${textToPrepend}`;
};

export const arrUniqueByAttr = (attr: string | number, arrays: any[]): any[] => {
  const flags: any = {};
  return arrays.filter((entry) => {
    if (flags[entry[attr]]) {
      return false;
    }
    flags[entry[attr]] = true;
    return true;
  });
};

export const createPayload = <T extends {}>(data: T, id?: string, type?: string) => {
  const payload: T = {} as T;
  Object.assign(payload, data);

  return {
    data: { id, type, attributes: payload },
  };
};

export const isInvalidControl = (formGroup: UntypedFormGroup, controlName: string) => {
  const control = formGroup.get(controlName);
  return control!.invalid && (control!.dirty || control!.touched);
};

export const toRequestObject = (object: any, fields: string[]) => {
  if (object.data && object.data.attributes) {
    object.data.relationships = object.data.relationships ? object.data.relationships : {};
    fields.forEach((key) => {
      object.data.relationships[key] = { data: object.data.attributes[key] };
      delete object.data.attributes[key];
    });
  }

  return object;
};

export const getFormControl = (formGroup: UntypedFormGroup, controlName: string): AbstractControl | undefined => {
  return formGroup.get(controlName) || undefined;
};

export const removeDuplicatesFromArray = <T>(arr: T[]) => [...new Set(arr)];

export const matchUnitsIntoGeoJson = (
  availableUnits: ProjectUnit[],
  geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
  hasUnitFilter?: boolean
) => {
  const nonBookedAndSoldOut: string[] = [];
  const unitDetail: any = {};
  const geoJsonCopy = JSON.parse(JSON.stringify(geoJson));
  const bedRooms: any = [],
    bathRooms: any = [],
    ranges: any = [],
    mohPrices: any = [];

  availableUnits.forEach((unit: any) => {
    bedRooms.push(unit.bedroom_count);
    bathRooms.push(unit.bathroom_count);
    ranges.push(unit.unit_size);
    mohPrices.push(unit.moh_price);

    const keyToGet = unit.unit_type === 'apartment' ? unit.building_number : unit.land_number;
    if (!nonBookedAndSoldOut.includes(keyToGet)) nonBookedAndSoldOut.push(keyToGet);
    unitDetail[keyToGet] = {
      unitType: unit.unit_type,
      auctionStatus: unit.auction_status,
      salesChannels: unit.sales_channels,
      id: unit.id,
      blockNumber: unit.block_number,
      bathroomCount: unit.bathroom_count,
      bedroomCount: unit.bedroom_count,
      minTotalPrice: unit.min_total_price,
      mohPrice: unit.moh_price,
      price: unit.price,
      startingPrice: unit.starting_price,
      unitCode: unit.unit_code,
      unitSize: unit.unit_size,
      model: unit.model,
      buildingNumber: unit.building_number,
      isApartment: unit.unit_type === 'apartment',
    };
  });

  let results: any = geoJsonCopy.features.map((feature: any) => {
    const { SUBDIVISIONPARCELNUMBER, text } = feature.properties;
    const keyToFetch = SUBDIVISIONPARCELNUMBER || text;
    const auctionStatus = unitDetail[keyToFetch]?.auctionStatus;
    const unitType = unitDetail[keyToFetch]?.unitType;
    const id = unitDetail[keyToFetch]?.id;
    const blockNumber = unitDetail[keyToFetch]?.blockNumber;
    const bathroomCount = unitDetail[keyToFetch]?.bathroomCount;
    const bedroomCount = unitDetail[keyToFetch]?.bedroomCount;
    const minTotalPrice = unitDetail[keyToFetch]?.minTotalPrice;
    const mohPrice = unitDetail[keyToFetch]?.mohPrice;
    const price = unitDetail[keyToFetch]?.price;
    const startingPrice = unitDetail[keyToFetch]?.startingPrice;
    const unitCode = unitDetail[keyToFetch]?.unitCode;
    const unitSize = unitDetail[keyToFetch]?.unitSize;
    const model = unitDetail[keyToFetch]?.model;
    const salesChannels = unitDetail[keyToFetch]?.salesChannels;
    const buildingNumber = unitDetail[keyToFetch]?.buildingNumber;

    let status: string = EsriMapPolygonStatus.BOOKED;
    let soldOut: boolean = true;
    let booked: boolean = true;

    if (keyToFetch === '') {
      status = EsriMapPolygonStatus.SOLD_OUT;
    } else if (nonBookedAndSoldOut.includes(keyToFetch)) {
      booked = false;
      soldOut = false;
      switch (auctionStatus) {
        case AuctionStatus.NOT_STARTED:
          status = EsriMapPolygonStatus.LAND_AUCTION_NOT_STARTED;
          break;
        case AuctionStatus.OPEN:
          status = EsriMapPolygonStatus.LAND_AUCTION_OPEN;
          break;
        case AuctionStatus.CLOSED:
        case AuctionStatus.EXPIRED:
        case AuctionStatus.SOLD:
        case AuctionStatus.WAITING_FOR_CONTRACT:
        case AuctionStatus.WAITING_FOR_PAYMENT:
          status = EsriMapPolygonStatus.LAND_AUCTION_CLOSED_EXPIRED;
          break;
        case AuctionStatus.NOT_TO_BID:
          const hasWeb = salesChannels.includes('web');
          const hasMobile = salesChannels.includes('mobile');
          const hasPartner = salesChannels.includes('partners');

          if (hasWeb && hasMobile) {
            status = EsriMapPolygonStatus.ONLINE;
          } else if (hasWeb) {
            status = EsriMapPolygonStatus.ONLINE_WEB;
          } else if (hasMobile) {
            status = EsriMapPolygonStatus.ONLINE_APP;
          } else if (hasPartner) {
            status = EsriMapPolygonStatus.OFFLINE;
          }
          break;
        default:
          status = EsriMapPolygonStatus.NONE;
      }
    }

    return {
      ...feature,
      properties: {
        ...feature.properties,
        id: id || '',
        status: status || '',
        price: price || 0,
        model: model || '',
        sold_out: soldOut.toString(),
        booked: booked.toString(),
        unit_type: unitType || '',
        land_number: keyToFetch || '',
        auction_status: auctionStatus || '',
        block_number: blockNumber || '',
        bathroom_count: bathroomCount || 0,
        bedroom_count: bedroomCount || 0,
        moh_price: mohPrice || 0.0,
        unit_code: unitCode || '',
        unit_size: unitSize || 0.0,
        min_total_price: minTotalPrice || 0.0,
        starting_price: startingPrice?.toString() || '',
        minBedRoom: Math.min(...bedRooms)?.toString() || '',
        maxBedRoom: Math.max(...bedRooms)?.toString() || '',
        minBathRoom: Math.min(...bathRooms)?.toString() || '',
        maxBathRoom: Math.max(...bathRooms)?.toString() || '',
        minSize: Math.min(...ranges)?.toString() || '',
        maxSize: Math.max(...ranges)?.toString() || '',
        minMohPrice: Math.min(...mohPrices)?.toString() || '',
        maxMohPrice: Math.max(...mohPrices)?.toString() || '',
        building_number: buildingNumber?.toString() || '',
      },
    };
  });

  if (hasUnitFilter)
    results = results.filter((polygon: any) => nonBookedAndSoldOut.includes(polygon.properties.land_number));

  geoJsonCopy.features = results;
  return geoJsonCopy;
};

export const unitName = (unit?: ProjectUnit) => {
  const blockNumber = unit?.block_number;
  const landNumber = unit?.land_number;
  const apartmentNumber = unit?.apartment_number;
  const unitType = unit?.unit_type;

  if (!blockNumber) {
    return 'UNIT_CARD.UNTITLED_UNIT_NAME';
  }

  if (unitType) {
    switch (unitType) {
      case UnitType.APARTMENT:
        return apartmentNumber ? `${blockNumber}-${apartmentNumber}` : `${blockNumber}`;
      case UnitType.LAND:
      case UnitType.VILLA:
      case UnitType.TOWNHOUSE:
        return landNumber ? plotCode(blockNumber, landNumber) : `${blockNumber}`;
      default:
        return `${blockNumber}`;
    }
  } else {
    return `${blockNumber}`;
  }
};

export const getRegionKeyNameFromConstant = (regionId: string | number | undefined) => {
  return Constants.REGIONS.filter((region: any) => region.value === regionId?.toString())[0]?.label;
};

export const getRuleTexts = (rules: RuleResult[]) => {
  return rules!.map((rule: RuleResult) => {
    const ruleText = rule.details
      ? 'ELIGIBILITY.DETAILED_RULE_RESULTS.' + rule.details.toUpperCase()
      : 'ELIGIBILITY.INELIGIBLE_RULES.' + rule.rule_key.toUpperCase();
    return {
      ...rule,
      rule_text: ruleText,
    };
  });
};

export const timestampToFormatDDHHMMSS = (timestampt: number, translate?: TranslateService) => {
  const secNum = Math.floor(timestampt / 1000);
  const days: string | number = Math.floor(secNum / 86400);
  let hours: string | number = Math.floor((secNum - days * 86400) / 3600);
  let minutes: string | number = Math.floor((secNum - days * 86400 - hours * 3600) / 60);
  let seconds: string | number = secNum - days * 86400 - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  const daysText = translate ? translate.instant('COMMON.DAYS') : 'days';
  const dayText = translate ? translate.instant('COMMON.DAY') : 'day';
  return `${days} ${days > 1 ? daysText : dayText} ${hours}: ${minutes}: ${seconds}`;
};

export const timestampToFormatCountdown = (timestampt: number, translate: TranslateService) => {
  const secNum = Math.floor(timestampt / 1000);
  const days: string | number = Math.floor(secNum / 86400);
  let hours: string | number = Math.floor((secNum - days * 86400) / 3600);
  let minutes: string | number = Math.floor((secNum - days * 86400 - hours * 3600) / 60);
  let seconds: string | number = secNum - days * 86400 - hours * 3600 - minutes * 60;

  const daysText = days > 1 ? ` ${translate.instant('COMMON.DAYS')}` : ` ${translate.instant('COMMON.DAY')}`;
  const hoursText = hours > 1 ? ` ${translate.instant('COMMON.HOURS')}` : ` ${translate.instant('COMMON.HOUR')}`;
  const minutesText =
    minutes > 1 ? ` ${translate.instant('COMMON.MINUTES')}` : ` ${translate.instant('COMMON.MINUTE')}`;
  const secondsText =
    seconds > 1 ? ` ${translate.instant('COMMON.SECONDS')}` : ` ${translate.instant('COMMON.SECOND')}`;

  return `${days > 0 ? days + daysText + ' ' : ''}
  ${hours > 0 ? hours + hoursText + ' ' : ''}
  ${minutes > 0 ? minutes + minutesText + ' ' : ''}
  ${seconds + secondsText}`;
};

export const getLandImage = (landType: string) => {
  const imageLandUrl = `assets/images/lands/${landType}.jpg`;
  const banner: ImageThumbGalleryDataInterface = {
    sourceUrl: imageLandUrl,
    thumbUrl: imageLandUrl,
    originUrl: imageLandUrl,
  };

  return [banner];
};

export const getHttpErrorMessage = (
  translate: TranslateService,
  err: HttpErrorResponse,
  interpolateParams = {}
): string => {
  if (!(err instanceof HttpErrorResponse)) {
    return translate.instant('ERRORS.general_error');
  }
  try {
    const error = err.error?.errors;
    if (Array.isArray(error) && error.length > 0) {
      return error[0].detail || translate.instant(`ERRORS.${error[0].code || error[0].detail}`, interpolateParams);
    } else if (typeof error === 'string') {
      return translate.instant(`ERRORS.${error}`);
    } else {
      return error.detail || translate.instant(`ERRORS.${error.code || error.detail}`, interpolateParams);
    }
  } catch (_) {
    let error_status = err.status.toString();
    if (err.status >= 500 && err.status < 600) {
      error_status = '5XX';
    }
    return translate.instant(`ERRORS.TITLE_${error_status}`);
  }
};

export const blobPdfFromBase64String = (base64String: string): Blob => {
  const byteArray = Uint8Array.from(
    atob(base64String)
      .split('')
      .map((char) => char.charCodeAt(0))
  );
  return new Blob([byteArray], { type: 'application/pdf' });
};

export const dataURIToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const arrayRemove = (arr: Array<any>, value: any) => {
  return arr.filter(function (ele) {
    return ele !== value;
  });
};

export const afterVat = (value: number, rate = Constants.VAT_RATE_DEFAULT) => {
  return +(value * (rate + 1)).toFixed(2);
};

// safari (and firefox) throws error for "2022-05-20 10:08:42 +0300" formatted date. To workaround it, need to replace '-' with '/'
export const formatDateString = (date: string): string => {
  return date && date.indexOf(' ') >= 0 ? date.replace(/-/g, '/') : date;
};

export const formatNumericFormData = (form: UntypedFormGroup, formNames: string[] = []) => {
  const result: { [key in string]: any } = {};

  formNames.map((formName) => {
    let value = form?.get(formName)?.value;

    result[formName] = value && Number(value);
  });

  return result;
};

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const isNonSaudi = (decryptedNationalIdNumber?: string) =>
  decryptedNationalIdNumber?.startsWith('2') ? true : false;

export const isEmptyObject = (obj: any) => {
  return !obj || (obj && Object.keys(obj).length === 0);
};

export const saveFile = (content: any, fileName?: any) => {
  const file = new Blob([content]);
  const filePath = URL.createObjectURL(file);

  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
  a.href = filePath;
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(filePath);
};

export const getContrastYIQ = (hexcolor: string) => {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
};

export const fixedHeader = (isFixed: boolean, projectType?: string) => {
  const offlanLand = ProjectTypes.OFFPLAN_LAND_PRIVATE_LAND;
  const landsPrivate = ProjectTypes.LANDS_PRIVATE_LAND;
  const currentPath = window.location.pathname;
  const bannerEl = document.getElementsByClassName('banner-message');
  const mainEl = window.document.getElementsByClassName('place-maincontent');

  const noCache =
    !localStorage.getItem('projectReason') &&
    !localStorage.getItem('bookingToken') &&
    localStorage.getItem('hasNoRecommendations') !== 'true';
  if (noCache && bannerEl.length !== 0) {
    bannerEl[0]?.classList.remove('d-none');
    bannerEl[0]?.classList.add('d-block');
    mainEl[0]?.classList.add('list-main-page');
    mainEl[0]?.classList.remove('main-page');
  } else if (noCache) {
    bannerEl[0]?.classList.remove('d-block');
    bannerEl[0]?.classList.add('d-none');
    mainEl[0]?.classList.remove('list-main-page');
    mainEl[0]?.classList.add('main-page');
    return;
  }

  if (isFixed) {
    if (
      ((currentPath.indexOf('projects') > 0 && projectType && projectType === 'lands_private_land') ||
        (currentPath.indexOf('projects') > 0 && projectType && projectType === 'offplan_land_private_land')) &&
      getCurrentBookingInfo() === ''
    ) {
      bannerEl[0]?.classList.remove('d-block');
      bannerEl[0]?.classList.add('d-none');
      mainEl[0]?.classList.remove('list-main-page');
      mainEl[0]?.classList.add('main-page');
    } else {
      bannerEl[0]?.classList.remove('d-none');
      bannerEl[0]?.classList.add('d-block');
      mainEl[0]?.classList.add('list-main-page');
      mainEl[0]?.classList.remove('main-page');
    }
  } else {
    bannerEl[0]?.classList.remove('d-block');
    bannerEl[0]?.classList.add('d-none');
    mainEl[0]?.classList.remove('list-main-page');
    mainEl[0]?.classList.add('main-page');
  }
};

export const changeColorBanner = (bgClass: string = 'bg-light-yellow') => {
  const bgEl = document.getElementsByClassName('bg-light-yellow');
  setTimeout(() => {
    if (bgClass !== 'bg-light-yellow') {
      if (bgEl && bgEl[0]) {
        bgEl[0].classList.add('bg-light-red');
        bgEl[0].classList.remove('bg-light-yellow');
      }
    } else {
      const bgPinkEl = document.getElementsByClassName('bg-light-red');
      if (bgPinkEl && bgPinkEl[0]) {
        bgPinkEl[0].classList.add('bg-light-yellow');
        bgPinkEl[0].classList.remove('bg-light-red');
      }
    }
  }, 500);
};

export const getCurrentBookingInfo = () => {
  const bookingToken = localStorage.getItem('bookingToken')!;
  if (!bookingToken || bookingToken === 'undefined') {
    return '';
  }
  return JSON.parse(atob(bookingToken!.split('.')[1]));
};

export const formatNumberWithSeparator = (data: number | string): string => {
  return formatNumber(Number(data), 'en-US');
};

export const formatPhoneNumberAr = (phoneNumber: string): string => {
  return `&lrm;${phoneNumber}`;
};

/**
 *
 * @param data the value is number
 * Use [innerHtml] in html template
 * component ts:
 * get number(): string {
    return formatNumberWithSeparator(123445.56);
  }
 * template html:
 * <p class="fw-bold text-primary" [innerHtml]="number"></p>
 */
export const translateNumberWithCurrencyUnit = (data: number | string): string => {
  const number = formatNumberWithSeparator(data);
  return number.toString() + '&nbsp;' + `${currentLang() === Constants.AR ? 'ر.س' : 'SAR'}`;
};

export const formatDateTimeWithSeparator = (data: number | string | Date, format: string = 'dd/MM/yyyy'): string => {
  return formatDate(data, format, 'en-US');
};

/**
 *
 * @param data the value is date
 * Use [innerHtml] in html template
 * component ts:
 * get formattedWitAt() {
    return translateDateTime('2022-11-07T02:31:07.216Z');
  }
 * template html:
 * <p class="fw-bold text-primary" [innerHtml]="formattedWitAt"></p>
 */
export const translateDateTime = (data: number | string | Date): string => {
  if (!data) {
    return '';
  }
  const date = formatDateTimeWithSeparator(data);
  const hourMinute = formatDateTimeWithSeparator(data, 'hh:mm a');
  return (
    '&#x200E;' +
    date +
    '&nbsp;' +
    `${currentLang() === Constants.AR ? 'الساعة' : 'at'}` +
    '&nbsp;' +
    '&#x200E;' +
    hourMinute
  );
};

export const decoratedNumber = (number: number) => {
  return formatNumber(Number(number), 'en-US');
};

export const viewMode = (): any => {
  // const isResponsiveMode = window.matchMedia && window.matchMedia('(max-width: 480px)').matches;
  // return isResponsiveMode ? 'mobile_web_responsive' : 'web';
  let isResponsiveMode = 'web';
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    isResponsiveMode = 'mobile_web_responsive';
  }
  return isResponsiveMode;
};

export const getUrlWithoutParams = (router: Router, url?: string) => {
  let urlTree = router.parseUrl(url || router.url);
  urlTree.queryParams = {};
  urlTree.fragment = null;
  return urlTree.toString();
};

export const fromObjectToObjectQueryParams = (obj: any, source: any = obj, key: string = ''): any => {
  Object.keys(obj).forEach((k) => {
    if (obj[k] && isObject(obj[k]) && !Array.isArray(obj[k])) {
      fromObjectToObjectQueryParams(obj[k], obj, k);
      delete obj[k];
    } else if (key) {
      source[key + '.' + k] = obj[k];
      if (!source[key + '.' + k]) {
        delete source[key + '.' + k];
      }
    }
  });
};

export const fromObjectQueryParamsToObject = (obj: any, source: any = obj, key: string = ''): any => {
  Object.keys(obj).forEach((k) => {
    if (k.includes('.')) {
      const keys = k.split('.');
      splitKey(keys, obj, obj[k]);
      delete obj[k];
    }
  });
};

export const splitKey = (keys: string[], obj: any, value: any): any => {
  const next = keys.shift();
  if (next) {
    if (keys.length > 0) {
      obj[next] = obj[next] ? obj[next] : {};
      splitKey(keys, obj[next], value);
    } else {
      obj[next] = value;
    }
  }
};

export const voucherPercentageUpto = (voucher: Promotion, translate: TranslateService): number | any => {
  switch (voucher?.voucher_code_type!) {
    case VoucherCodeType.QR_CODE:
      return translate.instant('VOUCHER.SINGLE.DISCOUNT_AMOUNT_TEXT', {
        discountAmount: voucher?.discount_amount!,
      });
    case VoucherCodeType.COUPON_CODE:
      if (voucher?.coupons?.length! > 1) {
        return translate.instant('VOUCHER.MULTI.DISCOUNT_AMOUNT_TEXT', {
          discountAmount: voucher?.coupon_discount_percentage_max!,
        });
      } else {
        const firstCoupon = voucher && voucher?.coupons && voucher?.coupons[0];
        return translate.instant('VOUCHER.SINGLE.DISCOUNT_AMOUNT_TEXT', {
          discountAmount: firstCoupon?.coupon_percentage!,
        });
      }
    default:
      return;
  }
};

export const plotCode = (blockNumber?: string, landNumber?: string) => {
  return `${blockNumber || ''}-${landNumber || ''}`;
};

export const convertToInternationalCurrencySystem = (
  labelValue: number,
  language = 'ar'
): { value: string; valueText: string } => {
  const absValue = Math.abs(Number(labelValue));
  const billionText = language === 'ar' ? 'مليار' : 'B';
  const millionText = language === 'ar' ? 'مليون' : 'M';
  const thousandText = language === 'ar' ? 'ألف' : 'K';
  if (absValue >= 1.0e9) {
    const roundUp = absValue >= 1.0e10 ? 0 : 1;
    return {
      value: (absValue / 1.0e9).toFixed(roundUp) + ' ',
      valueText: billionText,
    }; //billion
  } else if (absValue >= 1.0e6) {
    const roundUp = absValue >= 1.0e7 ? 0 : 1;
    return {
      value: (absValue / 1.0e6).toFixed(roundUp) + ' ',
      valueText: millionText,
    };
  } else if (absValue >= 1.0e3) {
    const roundUp = absValue >= 1.0e4 ? 0 : 1;
    return {
      value: (absValue / 1.0e3).toFixed(roundUp) + ' ',
      valueText: thousandText,
    };
  } else {
    return {
      value: absValue.toString(),
      valueText: '',
    };
  }
};

export const objKeyToStringArray = (obj: Object): string[] => {
  const result: string[] = [];
  for (const [k, v] of Object.entries(obj)) {
    if (v) {
      result.push(k);
    }
  }
  return result;
};

export const stringArrToObjKey = (arr: string[]): any => {
  return arr.reduce((r, key) => {
    return {
      ...r,
      [key]: true,
    };
  }, {});
};

export const getTagsWithPriority = (
  array: any[] | undefined,
  priorityTags = ['registering_interest', 'sold_out', 'registering_waiting_list']
) => {
  if (!array) {
    return [];
  }
  if (array.length < 3) {
    return array;
  }

  const result = [];
  const notPriorityTags = [];

  for (let i = 0; i < array.length; i++) {
    const [tag, value] = array[i];
    if (priorityTags.includes(tag)) {
      result.push([tag, value]);
      if (result.length === 2) {
        break; // Exit the loop once two tags are found
      }
    } else {
      notPriorityTags.push([tag, value]);
    }
  }
  if (result.length === 0) {
    return array.slice(0, 2); // Return a sliced array of maximum two elements
  }

  if (result.length === 1) {
    return [...result, ...notPriorityTags.slice(0, 1)]; // Return a sliced array of maximum two elements
  }

  return result;
};

export const getCenterOfPolygon = (coordinates: [number, number][]): [number, number] => {
  const DEFAULT_CENTER = [Constants.DEFAULT_CENTER_LAT, Constants.DEFAULT_CENTER_LON] as [number, number];
  if (!coordinates) {
    return DEFAULT_CENTER;
  }
  const numPoints: number = coordinates.length;

  // Calculate the sum of latitudes and longitudes
  let sumLat: number = 0;
  let sumLng: number = 0;
  for (let i: number = 0; i < numPoints; i++) {
    sumLat += coordinates[i][0];
    sumLng += coordinates[i][1];
  }

  // Calculate the average latitude and longitude
  const avgLat: number = sumLat / numPoints;
  const avgLng: number = sumLng / numPoints;

  // Return the center coordinates
  return [avgLat, avgLng];
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const flattenObject = (obj: any, flatted: any) => {
  if (!isObject(obj)) return;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (isObject(value) && value !== null && !Array.isArray(value)) {
      flatted = flattenObject(obj[key], flatted);
    } else {
      flatted[key] = obj[key];
    }
  });

  return flatted;
};

export const isInsideBoudingBox = (tl: LocationData, br: LocationData, loc: LocationData): boolean => {
  return tl.lat! >= loc.lat! && tl.lon! <= loc.lon! && loc.lat! >= br.lat! && loc.lon! <= br.lon!;
};

export const getExtension = (media: any): string => {
  let splits = ['file'];
  if (media.url) {
    splits = media.url.split('.');
  }
  return splits[splits.length - 1];
};

export const evenRound = (num: number, decimalPlaces: number) => {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

export const getFileExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
};

export const isAppliedFinancingGuaranteeJourney = (
  isEligible?: boolean,
  active_financing_guarantee_journey?: boolean,
  isOffPlan = true
): boolean => {
  return !!isEligible && !!isOffPlan && !!active_financing_guarantee_journey;
};

export const maximumAffordable = (beneficiary?: Beneficiary, active_financing_guarantee_journey?: boolean): number => {
  if (!beneficiary?.purchase_power) {
    return 0;
  }

  return isAppliedFinancingGuaranteeJourney(
    beneficiary.isEligibleNotInGroupNonBeneficiary,
    active_financing_guarantee_journey
  )
    ? beneficiary?.purchase_power / Constants.MINIMUM_PURCHASING_POWER_PERCENTAGE
    : beneficiary?.purchase_power;
};

export const shouldGoToDisclaimerPage = (isEligible?: boolean, project?: Project) => {
  return project?.use_booking_fee_flag || (project?.project_type === ProjectTypes.OFFPLAN_MOH_LAND && !!isEligible);
};

export const isNullOrEmptyString = (value: string) => {
  return !value || value.length === 0;
};

export const getValue = (object: any, props: string) => {
  let res = object;
  for (const prop of props.split('||')) {
    res = object;
    prop
      .trim()
      .split('.')
      .forEach((childProp) => {
        childProp = childProp.trim();
        if (!res) {
          return '';
        } else {
          res = res[childProp];
        }
        return '';
      });
    if (!isNullOrEmptyString(res)) {
      break;
    }
  }

  switch (res) {
    case '':
    case undefined:
      return '';
    default:
      return res;
  }
};

export const reachMaximumFetchFRCRetryTimes = (retryTimes?: number): boolean => {
  if (!retryTimes) {
    return false;
  }
  return retryTimes >= Constants.FETCH_FRC_RETRY_TIMES_MAXIMUM;
};

export const useFinancialCertification = (projectType: ProductTypes) => {
  return projectType === ProductTypes.OFF_PLAN_UNIT;
};

export const isMobileWebViewOnDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
};

declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}
export const webviewCallHandler = (name: string, data: any) => {
  try {
    window.flutter_inappwebview.callHandler(name, data);
  } catch (e) {
    console.log({ e });
  }
};

export const productTypeMapping = (projectType: string) => {
  switch (projectType) {
    case 'readymade_private_land':
    case 'readymade_moh_land':
      return ProductTypeTexts.ReadymadeMOHUnit;

    case 'offplan_private_land':
    case 'offplan_moh_land':
      return ProductTypeTexts.UnderConstructionUnit;

    default:
      return ProductTypeTexts.ReadymadeMarketUnit;
  }
};

export const isAppleDevices = (navigator: Navigator) => /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

export const getDownloadSakaniAppUrl = (navigator: Navigator) => {
  console.log(isAppleDevices(navigator));
  return isAppleDevices(navigator)
    ? Constants.DOWNLOAD_SAKANI_APP_APPSTORE_URL
    : Constants.DOWNLOAD_SAKANI_APP_PLAYSTORE_URL;
};

/**
 * Deeply compares two values for equality.
 * Supported types:
 * - Primitives (number, string, boolean, null, undefined)
 * - Arrays (compares element-by-element recursively)
 * - Objects (compares keys and values recursively)
 * - Nested structures with mixed types
 *
 * @param value1 - First value to compare.
 * @param value2 - Second value to compare.
 * @returns `true` if values are deeply equal, otherwise `false`.
 */
export const isEqual = <T>(value1: T, value2: T): boolean => {
  if (value1 === value2) return true;
  if (!value1 || !value2) return false;
  if (typeof value1 !== typeof value2) return false;

  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every((item, index) => isEqual(item, value2[index]));
  }

  if (typeof value1 === 'object') {
    const keys1 = Object.keys(value1 || {}) as Array<keyof typeof value1>;
    const keys2 = Object.keys(value2 || {}) as Array<keyof typeof value2>;

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => isEqual(value1[key], value2[key as keyof typeof value2]));
  }

  return false;
};

/**
 * Checks if a value is `null` or `undefined`.
 * @param value - The value to check.
 * @returns `true` if the value is `null` or `undefined`, otherwise `false`.
 */
export const isNil = (value: unknown): value is null | undefined => {
  return value == null;
};

/**
 * Safely retrieves a nested property value from an object.
 * @param obj - The object to retrieve the value from.
 * @param path - The path to the property (e.g., 'a.b.c').
 * @param defaultValue - A default value to return if the path does not exist.
 * @returns The value at the given path or the default value if not found.
 */
export const getObjectValueByPath = <T, R>(
  obj: T,
  path: string | Array<string | keyof T>,
  defaultValue?: R
): R | undefined => {
  if (!obj) return defaultValue;

  const keys = Array.isArray(path) ? path : (path as string).split('.');
  let result: any = obj;

  for (const key of keys) {
    result = result?.[key as keyof T];
    if (result === undefined) return defaultValue;
  }

  return result;
};

/**
 * Omits specified keys from an object.
 * @param obj - The source object.
 * @param keysToOmit - The keys to omit from the object.
 * @returns A new object without the specified keys.
 */
export const omitKeyOfObject = <T extends object, K extends keyof T>(obj: T, keysToOmit: K[]): Omit<T, K> => {
  const result = {} as Omit<T, K>;

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    if (!keysToOmit.includes(key as K)) {
      (result as T)[key] = obj[key];
    }
  });

  return result;
};

/**
 * Checks if a given value is empty.
 *
 * @param value - The value to check.
 * @returns `true` if the value is empty, `false` otherwise.
 *
 * Empty is defined as:
 * - `null` or `undefined`
 * - An array with no elements
 * - An object with no own properties
 * - A string with no non-whitespace characters
 */
export const isEmpty = <T = any>(value: T): boolean => {
  if (value == null) return true;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  if (typeof value === 'string') return value.trim().length === 0;

  return false;
};
