import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastContainerComponent } from './toast-container.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [ToastContainerComponent],
  imports: [CommonModule, NgbToastModule, LayoutDirectionModule],
  exports: [ToastContainerComponent],
})
export class ToastContainerModule {}
