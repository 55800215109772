<nav class="navbar navbar-expand-lg py-md-20px z-3 v5" appLayoutDirection>
  <ng-container *ngIf="mobileToggle">
    <div class="mobile-header d-lg-none navbar-toggle d-flex flex-row justify-content-between w-100 align-items-center">
      <div class="d-flex flex-row align-items-center">
        <div class="toggle-icon px-1">
          <span
            type="button"
            (click)="hamburgerIcon = !hamburgerIcon"
            class="svg-icon py-6"
            [ngClass]="hamburgerIcon ? 'icon-hamburger' : 'svg-icon--small icon-close-black'"
            aria-label="Toggle navigation"
          ></span>
        </div>
        <a class="text-decoration-none m-start-1" href="/">
          <img
            src="assets/images/nhc-marketplace/nhc-cityscape-logo.svg"
            class="app-logo"
            height="32"
            alt="Sakani logo"
          />
        </a>
      </div>

      <div class="d-flex align-items-center px-0">
        <span class="svg-icon icon-nhc-icon-profile"></span>
      </div>
    </div>

    <div class="nav-mobile" *ngIf="!hamburgerIcon">
      <div class="d-flex flex-column">
        <div class="nav-item w-100 cursor-pointer">
          <a
            class="nav-link px-0 d-flex flex-row justify-content-between align-items-center text-decoration-none text-neutral-n6"
            routerLink="/marketplace"
            routerLinkActive="current-item fw-bold text-neutral-n7"
          >
            <span class="mx-3">
              {{ 'NAVIGATION.NHC.MARKETPLACE' | translate }}
            </span>
          </a>
        </div>
        <div class="nav-item w-100 cursor-pointer">
          <a
            (click)="changeLanguage()"
            class="nav-link px-0 d-flex flex-row justify-content-between align-items-center text-decoration-none"
          >
            <span class="mx-3">
              {{ currentLang === 'ar' ? 'English' : 'عربي' }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Desktop view -->
  <ng-container *ngIf="!mobileToggle">
    <div class="container">
      <div class="collapse navbar-collapse" id="main_nav">
        <div class="navbar-nav justify-content-between w-100 d-flex flex-row align-items-center">
          <div class="d-flex flex-row align-items-center gap-5">
            <a class="text-decoration-none py-1 z-index-fixed" routerLink="/">
              <img
                src="assets/images/nhc-marketplace/nhc-cityscape-logo.svg"
                class="app-logo"
                height="40"
                width="80"
                alt="NHC logo"
              />
            </a>
            <a
              class="py-2 z-index-fixed text-decoration-none text-neutral-n6"
              routerLink="/marketplace"
              routerLinkActive="current-item fw-bold text-neutral-n7"
            >
              {{ 'NAVIGATION.NHC.MARKETPLACE' | translate }}
            </a>
          </div>

          <div class="d-flex flex-row align-items-center">
            <a [href]="sakaniPath" class="btn btn-nhc-outline-brand">
              {{ 'NHC.TOPBAR.BACK_TO_SAKANI_BUTTON' | translate }}
            </a>
            <li class="m-start-4 nav-item small cursor-pointer fw-bold text-neutral-n7" (click)="changeLanguage()">
              {{ currentLang === 'ar' ? 'English' : 'عربي' }}
            </li>

            <li class="m-start-4 nav-item small">
              <span class="d-none d-lg-block">
                <ng-container *ngIf="session?.isAuthenticated">
                  <div class="nav-link d-flex flex-row align-items-center px-0 py-2 gap-2">
                    <span class="d-flex flex-column">
                      <span class="text-muted fs-xs">{{ 'NAVIGATION.HELLO' | translate }}</span>
                      <span class="fw-bold">
                        {{ fullName() }}
                      </span>
                    </span>
                    <span class="svg-icon icon-nhc-icon-profile svg-icon--new-brand-p1"></span>
                  </div>
                </ng-container>
              </span>
            </li>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nav>
