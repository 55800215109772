import { ImageThumbGalleryDataInterface } from '@app/shared/interfaces';

export class UnitModel {
  relationships = {};

  constructor(
    public id?: string,
    public model?: string,
    public unit_type?: string,
    public moh_price_min?: number,
    public moh_price_max?: number,
    public available_units_count?: number,
    public publish?: boolean,
    public media?: any,
    public data?: any,
    public units_statistic_data?: any,
    public last_published_media?: any,
    public auction_fee_flag?: boolean,
    public apply_general_setting?: boolean,
    public added_time?: number,
    public auction_fee_amount?: string,
    public fixed_increase_numbers?: number[],
    public contract_action_period?: number,
    public pay_final_price_period?: number,
    public auction_setting_snapshot_values?: any,
    public winner_contract_url?: string,
    public term_and_condition_url?: string
  ) {}

  get mediaObject() {
    return this.media || this.last_published_media;
  }

  get unitModelTextual() {
    if (this.mediaObject && this.mediaObject.textual && this.mediaObject.textual.attributes) {
      return this.mediaObject.textual.attributes.meta;
    }
    return {};
  }

  get masterPlanMedia() {
    if (this.mediaObject && this.mediaObject.master_plan && this.mediaObject.master_plan.attributes) {
      return this.mediaObject.master_plan.attributes;
    }
    return {};
  }

  get floorPlanMedia() {
    if (this.mediaObject && this.mediaObject.floor_plan) {
      return this.mediaObject.floor_plan.map((item: any) => item.attributes);
    }
    return {};
  }

  get unitModelBanner() {
    if (this.mediaObject && this.mediaObject.banner) {
      return this.mediaObject.banner.attributes;
    }
    return {};
  }

  get unitModelVideo() {
    if (this.mediaObject && this.mediaObject.video) {
      return this.mediaObject.video.attributes;
    }
    return {};
  }

  get unitModelGallery(): ImageThumbGalleryDataInterface[] {
    const video: ImageThumbGalleryDataInterface = {
      sourceUrl: this.mediaObject?.video?.attributes?.url,
      thumbUrl: this.mediaObject?.video?.attributes?.thumbnail_url,
      originUrl: this.mediaObject?.video?.attributes?.url,
      type: 'video',
    };

    const banner: ImageThumbGalleryDataInterface = {
      sourceUrl: this.mediaObject?.banner?.attributes?.url,
      thumbUrl: this.mediaObject?.banner?.attributes?.thumbnail_url,
      originUrl: this.mediaObject?.banner?.attributes?.url,
    };

    const master_plan: ImageThumbGalleryDataInterface = {
      sourceUrl: this.mediaObject?.master_plan?.attributes?.url,
      thumbUrl: this.mediaObject?.master_plan?.attributes?.thumbnail_url,
      originUrl: this.mediaObject?.master_plan?.attributes?.url,
    };

    const rawGallery = this.mediaObject?.gallery || [];
    const gallery = rawGallery?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url,
        originUrl: g?.attributes?.url,
      };
      return data;
    });

    const rawFloorPlan = this.mediaObject?.floor_plan || [];
    const floorPlan = rawFloorPlan?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url,
        originUrl: g?.attributes?.url,
      };
      return data;
    });

    // include ground floor, first floor, second floor
    const rawFloorImage = this.mediaObject?.floor_image || [];
    const floorImage = rawFloorImage?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url,
        originUrl: g?.attributes?.url,
      };
      return data;
    });

    let unitModelImages: ImageThumbGalleryDataInterface[] = [];
    video.sourceUrl ? unitModelImages.push(video) : '';
    banner.sourceUrl ? unitModelImages.push(banner) : '';
    master_plan.sourceUrl ? unitModelImages.push(master_plan) : '';

    unitModelImages = [...unitModelImages, ...gallery, ...floorPlan, ...floorImage];

    return unitModelImages;
  }
}
