import { GroupUnit } from './group-unit';
import { JsonApi } from './json-api';
import { MarketUnit } from './market-unit';
import { MegaProject } from './mega-project';
import { Project } from './project';
import { UnitModel } from './unit-model';
import { MarketingRentalUnit } from './marketing-rental-unit';
import { MarketplaceUnitModel } from './marketplace-unit-model';
import { QueryMegaProject } from './query-mega-project';

export class FavoriteProject {
  relationships = {
    group_unit: { rel: 'has_one', model: GroupUnit },
    market_unit: { rel: 'has_one', model: MarketUnit },
    marketing_rental_unit: { rel: 'has_one', model: MarketingRentalUnit },
    mega_project: { rel: 'has_one', model: MegaProject },
    project: { rel: 'has_one', model: Project },
    unit: { rel: 'has_one', model: UnitModel },
    unit_model: { rel: 'has_one', model: MarketplaceUnitModel },
  };
  constructor(
    public resource_id?: number,
    public resource_type?: string,
    public subscription_type?: string,
    public beneficiary_national_id_number?: string,
    public extra_info?: any,
    public project?: Project,
    public mega_project?: QueryMegaProject,
    public unit?: UnitModel,
    public unit_model?: MarketplaceUnitModel,
    public market_unit?: MarketUnit,
    public group_unit?: GroupUnit,
    public marketing_rental_unit?: MarketingRentalUnit
  ) {}

  toProject(): Project {
    if (this.resource_type !== 'projects') {
      throw new Error('Can not convert to Project');
    }

    return JsonApi.parseJsonApi(Project, {
      id: this.resource_id,
      type: 'projects',
      attributes: { ...this.project, is_favorite: true },
    });
  }

  toMegaProject(): QueryMegaProject {
    if (this.resource_type !== 'mega_projects') {
      throw new Error('Can not convert to Mega Project');
    }

    return JsonApi.parseJsonApi(QueryMegaProject, {
      id: this.resource_id,
      type: 'mega_projects',
      attributes: {
        ...this.mega_project,
        id: this.resource_id,
        content: this.mega_project?.content,
        cover_image: this.mega_project?.data.attributes.banner_image.url,
        title: this.mega_project?.title,
        city: this.mega_project?.data.attributes.city_obj,
        region: this.mega_project?.data.attributes.region_obj,
        total_units_count: this.mega_project?.data.attributes.apartment_count,
        total_area: this.mega_project?.total_area,
        is_favorite: true,
      },
    });
  }

  toUnit(): MarketplaceUnitModel {
    if (this.resource_type !== 'units') {
      throw new Error('Can not convert to Unit');
    }

    return JsonApi.parseJsonApi(MarketplaceUnitModel, {
      id: this.resource_id,
      type: 'units',
      attributes: { ...this.unit, is_favorite: true },
    });
  }

  toUnitModel(): MarketplaceUnitModel {
    if (this.resource_type !== 'unit_models') {
      throw new Error('Can not convert to UnitModel');
    }

    return JsonApi.parseJsonApi(MarketplaceUnitModel, {
      id: this.resource_id,
      type: 'unit_models',
      attributes: {
        ...this.unit_model,
        project_id: this.resource_id,
        is_favorite: true,
      },
    });
  }

  toMarketUnit(): MarketUnit {
    if (this.resource_type != 'market_units') {
      throw 'Can not convert to MarketUnit';
    }

    return JsonApi.parseJsonApi(MarketUnit, {
      id: this.resource_id,
      type: 'market_units',
      attributes: { ...this.market_unit, is_favorite: true },
    });
  }

  toGroupUnit(): GroupUnit {
    if (this.resource_type !== 'group_units') {
      throw new Error('Can not convert to GroupUnit');
    }

    return JsonApi.parseJsonApi(GroupUnit, {
      id: this.resource_id,
      type: 'group_units',
      attributes: { ...this.group_unit, is_favorite: true },
    });
  }

  toRentalUnit(): MarketingRentalUnit {
    if (this.resource_type !== 'marketing_rental_units') {
      throw new Error('Can not convert to RentalUnits');
    }

    return JsonApi.parseJsonApi(MarketingRentalUnit, {
      id: this.resource_id,
      type: 'marketing_rental_units',
      attributes: { ...this.marketing_rental_unit, is_favorite: true },
    });
  }
}

export class FavoriteProjectResponse {
  constructor(public data: FavoriteProject[], public included?: Array<any>) {}
}

export class FavoriteProjectLocalStorage {
  constructor(public resource_type?: string, public favorites?: (number | undefined)[]) {}
}
