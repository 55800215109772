import { AuctionType, EligibilityStatus, ProjectTypes, SubsidizeLevel, UnitType } from '@app/enums';
import { Constants } from '@app/helpers';
import { ImageSliderDataInterface, ImageThumbGalleryDataInterface } from '@app/shared/interfaces';
import { Amenity, ProjectAmenity } from './amenity';
import { Auction } from './auction';
import { City } from './city';
import { Developer } from './developer';
import { ProjectUnitType } from './project-unit-type';
import { Region } from './region';
import { UnitStatistic } from './unit-statistic';

export class Project {
  relationships = {
    developer: { rel: 'has_one', model: Developer },
    amenities: { rel: 'has_many', model: Amenity },
    projects_amenities: { rel: 'has_many', model: ProjectAmenity },
    project_unit_types: { rel: 'has_many', model: ProjectUnitType },
  };

  developer?: Developer;
  amenities?: Amenity[];
  projects_amenities?: ProjectAmenity[];

  constructor(
    public id?: number,
    public name?: string,
    public media_name?: string,
    public developer_name?: string,
    public developer_logo_url?: string,
    public media?: any,
    public latitude?: number,
    public longitude?: number,
    public description?: string,
    public summary?: string,
    public project_unit_types?: ProjectUnitType[],
    public dropdown_criteria?: string[],
    public start_date?: string,
    public end_date?: string,
    public all_units_count?: number,
    public available_units_count?: number,
    public units_available_soon?: boolean,
    public project_type?: ProjectTypes,
    public region_id?: number,
    public city_id?: number,
    public district?: string,
    public units_statistic_data?: UnitStatistic,
    public developer_cr_number?: string,
    public code?: string,
    public bookable?: boolean,
    public building_permit_period_in_months?: number,
    public automatic_cancel_delay_in_days_value?: number,
    public subdivision_request_number?: number,
    public phase?: string,
    public plan_number?: number,
    public subsidize_level?: string,
    public communication_message?: string,
    public region_obj?: Region,
    public city_obj?: City,
    public web_url?: string,
    public realtime_available_units_count?: number,
    public price_starting_at?: number,
    public views_count: number = 10,
    public location?: any,
    public subsidizable?: boolean,
    public sort_value?: any,
    public marketplace_name?: string,
    public mullak_content_flag?: boolean,
    public latest_auction_unit?: Auction,
    public booking_fee?: number,
    public use_booking_fee_flag?: boolean,
    public use_register_interest_flag?: boolean,
    public can_request_conveyance_on_project?: boolean,
    public realtime_available_units_for_auctions_count?: number,
    public azm_item_code?: string,
    public link_with_azm_item?: string,
    public active_queueing?: boolean,
    public max_active_queueing_member?: number,
    public azm_item_status?: boolean,
    public completion_percentage_visible?: boolean,
    public media_visible?: boolean,
    public completion_percentage: number = 0,
    public completion_percentage_updated_at?: string,
    public wafi_content_flag?: boolean,
    public tags?: any[],
    public marketplace_price?: number,
    public starting_bid_price?: number,
    public extend_pq_fee?: number,
    public extend_pq_day?: number,
    public use_extend_pq_flag?: boolean,
    public free_extend_pq_fee?: boolean,
    public maximum_booking_per_non_beneficiary?: number,
    public active_multiple_bookings_for_web_mobile?: boolean,
    public active_financing_guarantee_journey?: boolean,
    public project_id?: number,
    public type?: string,
    public resource_type?: string,
    public resource_id?: number,
    public is_favorite?: boolean,
    public nhc_related?: boolean,
    public auction_type?: AuctionType,
    public is_nhc_project: boolean = false,
    public mega_project_id?: number
  ) {}

  get masterPlan() {
    if (this.media && this.media.master_plan && this.media.master_plan.attributes) {
      return this.media.master_plan.attributes;
    }
    return {};
  }

  get projectTextual() {
    if (this.media && this.media.textual && this.media.textual.attributes) {
      return this.media.textual.attributes.meta;
    }
    return {};
  }

  get projectBrochure() {
    if (this.media && this.media.brochure && this.media.brochure.attributes) {
      return this.media.brochure.attributes;
    }
    return {};
  }

  get availableUnitCount() {
    let availableUnits;
    if (this.realtime_available_units_count != null) {
      availableUnits = this.realtime_available_units_count;
    } else if (this.available_units_count != null) {
      availableUnits = this.available_units_count;
    } else {
      availableUnits = this.units_statistic_data?.available_units_count || 0;
    }
    return availableUnits;
  }

  get availableUnitCountForAuction() {
    return this.realtime_available_units_for_auctions_count || 0;
  }

  get selectedUnitCount() {
    return this.units_statistic_data?.selected_units_count || 0;
  }

  get imageGallery(): ImageSliderDataInterface[] {
    const rawGallery = this.media?.gallery || [];
    const banner: ImageSliderDataInterface = {
      sourceUrl: this.media?.banner?.attributes?.thumbnail_url,
    };
    const gallery = rawGallery?.map((g: any) => {
      const data: ImageSliderDataInterface = {
        sourceUrl: g?.attributes?.thumbnail_url,
      };
      return data;
    });
    const projectImages = [banner, ...gallery].filter((i) => i.sourceUrl);
    return projectImages;
  }

  get imageThumbsGallery(): ImageThumbGalleryDataInterface[] {
    const rawGallery = this.media?.gallery || [];
    const video: ImageThumbGalleryDataInterface = {
      sourceUrl: this.media?.video?.attributes?.url,
      thumbUrl: this.media?.video?.attributes?.thumbnail_url,
      originUrl: this.media?.video?.attributes?.url,
      type: 'video',
    };

    const banner: ImageThumbGalleryDataInterface = {
      sourceUrl: this.media?.banner?.attributes?.url,
      thumbUrl: this.media?.banner?.attributes?.thumbnail_url,
      originUrl: this.media?.banner?.attributes?.url,
    };

    // const master_plan: ImageThumbGalleryDataInterface = {
    //   sourceUrl: this.media?.master_plan?.attributes?.url,
    //   thumbUrl: this.media?.master_plan?.attributes?.thumbnail_url,
    //   originUrl: this.media?.master_plan?.attributes?.url,
    // };

    const gallery = rawGallery?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url,
        originUrl: g?.attributes?.url,
      };
      return data;
    });
    let projectImages: ImageThumbGalleryDataInterface[] = [];
    video.sourceUrl ? projectImages.push(video) : '';
    // banner.sourceUrl ? projectImages.push(banner) : '';
    // master_plan.sourceUrl ? projectImages.push(master_plan) : '';

    projectImages = [...projectImages, ...gallery];

    if (projectImages.length == 0 && banner.sourceUrl) {
      projectImages.push(banner);
    }

    return projectImages;
  }

  get lngLat() {
    const lat = +this.location?.latitude;
    const lng = +this.location?.longitude;
    const validLat = lat !== 0 && lat >= -90 && lat <= 90;
    const validLon = lng !== 0 && lng >= -90 && lng <= 90;
    if (validLat && validLon) {
      return [lng, lat];
    } else {
      return null;
    }
  }

  get isAuction(): boolean {
    return this.project_type === ProjectTypes.AUCTION;
  }

  get isLand() {
    return this.project_type === ProjectTypes.LANDS_MOH_LAND || this.project_type === ProjectTypes.LANDS_PRIVATE_LAND;
  }

  get isAdvanceSCLand() {
    return this.project_type === ProjectTypes.ADVANCED_SELF_CONSTRUCTION_LAND;
  }

  get isMohLand() {
    return this.project_type === ProjectTypes.LANDS_MOH_LAND;
  }

  get isOffPlan() {
    return (
      this.project_type === ProjectTypes.OFFPLAN_MOH_LAND || this.project_type === ProjectTypes.OFFPLAN_PRIVATE_LAND
    );
  }

  get isOffPlanMoh() {
    return this.project_type === ProjectTypes.OFFPLAN_MOH_LAND;
  }

  get isOffPlanPrivateLand() {
    return this.project_type === ProjectTypes.OFFPLAN_PRIVATE_LAND;
  }

  get isReadyMade() {
    return (
      this.project_type === ProjectTypes.READYMADE_MOH_LAND || this.project_type === ProjectTypes.READYMADE_PRIVATE_LAND
    );
  }

  get isPrivateProject() {
    return (
      (this.project_type === ProjectTypes.OFFPLAN_LAND_PRIVATE_LAND ||
        this.project_type === ProjectTypes.LANDS_PRIVATE_LAND) &&
      (this.subsidize_level === SubsidizeLevel.NOT_SUBSIDIZABLE || !this.subsidizable)
    );
  }

  get isLandOrPrivateProject() {
    return (
      this.project_type === ProjectTypes.LANDS_MOH_LAND ||
      this.project_type === ProjectTypes.LANDS_PRIVATE_LAND ||
      this.project_type === ProjectTypes.ADVANCED_SELF_CONSTRUCTION_LAND ||
      this.subsidize_level === SubsidizeLevel.NOT_SUBSIDIZABLE
    );
  }

  get isAdvancedSelfConstruction() {
    return this.project_type === ProjectTypes.ADVANCED_SELF_CONSTRUCTION_LAND;
  }

  get isLandPrivateProject() {
    return this.project_type === ProjectTypes.LANDS_PRIVATE_LAND;
  }

  get projectUrl() {
    if (this.isPrivateProject) {
      return `/private-projects/${this.id}`;
    } else {
      if (this.isOffPlan) {
        return `/offplan-projects/${this.id}`;
      } else if (this.isReadyMade) {
        return `/readymade-projects/${this.id}`;
      } else if (this.isAdvanceSCLand) {
        return `/asc-projects/${this.id}`;
      } else if (this.isAuction) {
        return `/auction-projects/${this.id}`;
      } else if (this.isLand) {
        return `/land-projects/${this.id}`;
      } else if (this.isAuction) {
        return `/auction-projects/${this.id}`;
      } else {
        return '#';
      }
    }
  }

  get projectGeoMap() {
    if (this.media && this.media.geo_map) {
      return this.media.geo_map.attributes;
    }
    return {};
  }

  get projectGeoPolygon() {
    if (this.media && this.media.geo_map_polygons) {
      return this.media.geo_map_polygons.attributes;
    }
    return {};
  }

  get projectGeoInfrastructure() {
    if (this.media && this.media.geo_map_infrastructures) {
      return this.media.geo_map_infrastructures.attributes;
    }
    return {};
  }

  get isFullyBooked(): boolean {
    if (!!this.units_available_soon) return false;
    return this.availableUnitCount === 0 && this.selectedUnitCount === 0;
  }

  get soldOutUnitTypes(): UnitType[] {
    const projectUnitTypes = this.project_unit_types;
    if (!projectUnitTypes) {
      return [];
    }

    return projectUnitTypes
      ?.map((item) => {
        return item.published_available_count === 0 && item.published_booked_count! > 0 ? item.unit_type : null;
      })
      .filter((item) => item != null) as UnitType[];
  }

  get allUnitSignedUnitTypes(): UnitType[] {
    const projectUnitTypes = this.project_unit_types || this.units_statistic_data?.project_unit_types;

    if (!projectUnitTypes) {
      return [];
    }

    return projectUnitTypes
      ?.map((item) => {
        return item.sold_out_all_unit_flag ? item.unit_type : null;
      })
      .filter((item) => item != null) as UnitType[];
  }

  get isSoldOut(): boolean {
    return this.supportedUnitTypes.every((unitType) => this.allUnitSignedUnitTypes.includes(unitType as UnitType));
  }

  get canRegisterInterest(): boolean {
    return this.use_register_interest_flag && this.isOffPlan && !this.bookable && !this.isFullyBooked ? true : false;
  }

  get projectHasPromotion() {
    let existed = false;
    for (const codeProject of Constants.CODE_PROJECTS) {
      if (this?.code === codeProject && this.isOffPlanMoh) {
        existed = true;
        break;
      }
    }
    return existed;
  }

  get webShowPromotion() {
    return this.units_statistic_data?.web_show_promotion;
  }

  get isClosed(): boolean {
    return !this.bookable && !this.isFullyBooked;
  }

  get isOpen(): boolean {
    return this.bookable! && !this.isFullyBooked;
  }

  get supportedUnitTypes(): string[] {
    return this.units_statistic_data?.unit_types || [];
  }
}
