import { ImageSliderDataInterface } from '@app/shared/interfaces';
import { MarketingRentalUnitStatus } from '@app/enums';
import { Region, City, District } from '.';
import { CoordinateInterface, TableInformationDataInterface } from '@app/interfaces';
import { getMappedKeyValue } from '@app/helpers';
import { SakaniMarkerInterface } from '@app/shared/interfaces/mapbox.interface';
import { LngLatLike } from '@app/helpers';

class AddressInfo {
  relationships = {};
  constructor(
    public neighborhood?: string,
    public region?: string,
    public city?: string,
    public region_obj?: Region,
    public city_obj?: City & {
      city_id?: number;
      province_id?: number;
      region_id?: number;
      redf_code?: number;
      classification?: number;
      key?: string;
    },
    public district_obj?: District
  ) {}
}
class Amenities {
  relationships = {};
  constructor(
    public parking_spaces?: number,
    public bedrooms?: number,
    public bathrooms_full?: number,
    public maid_room?: number,
    public kitchen?: number,
    public halls?: number,
    public is_kitchen_sink_installed?: boolean,
    public is_cabinet_installed?: boolean,
    public storeroom?: number,
    public backyard?: number,
    public majles?: number,
    public central_ac?: number,
    public split_unit?: number,
    public desert_cooler?: number,
    public number_of_rooms?: number
  ) {}
}
class Facilities {
  relationships = {};
  constructor(
    public gyms_fitness_centers?: number,
    public elevators?: number,
    public parking_spaces?: number,
    public security_entries?: number
  ) {}
}
class PropertyInfo {
  relationships = {};
  constructor(
    public id?: number,
    public property_type?: string,
    public property_usage?: string,
    public build_date?: string,
    public total_floors?: string,
    public units_per_floor?: string,
    public is_compound_property_part?: boolean,
    public compound_name?: string,
    public address?: AddressInfo,
    public associated_facilities?: Facilities
  ) {}
}
class BrokerageInfo {
  relationships = {};
  constructor(public id?: number, public brokerage_office_name?: string, public brokerage_office_cr_number?: number) {}
}

class UnitInfo {
  relationships = {};
  constructor(
    public id?: number,
    public unit_number?: number,
    public unit_type?: string,
    public unit_usage?: string,
    public area?: string,
    public floor_number?: number,
    public established_date?: string,
    public include_mezzanine?: boolean,
    public is_furnished?: boolean,
    public unit_dimensions?: { width: number; height: number; length: number },
    public unit_direction?: string,
    public unit_finishing?: string,
    public furnish_type?: string,
    public amenities?: Amenities,
    public number_of_rooms?: number
  ) {}
}

class Media {
  relationships = {};
  constructor(
    public id?: number | string,
    public filename?: string,
    public file_url?: string,
    public type?: string,
    public size?: number
  ) {}
}
class MarketingInfo {
  relationships = {};
  constructor(
    public id?: number,
    public branding_name?: string,
    public annual_unit_price?: number,
    public price_is_negotiable?: boolean,
    public marketing_phone_number?: string,
    public medias?: Array<Media>,
    public description?: number,
    public latitude?: number,
    public longitude?: number
  ) {}
}
export class MarketingUnitDetail {
  relationships = {};
  constructor(
    public id?: number,
    public brokerage_info?: BrokerageInfo,
    public marketing_info?: MarketingInfo,
    public unit_info?: UnitInfo,
    public property_info?: PropertyInfo
  ) {}
}
export class MarketingRentalUnit {
  relationships = {
    marketing_unit_detail: { rel: 'has_one', model: MarketingUnitDetail },
  };

  constructor(
    public id?: number,
    public area?: number,
    public annual_unit_price?: number,
    public price_is_negotiable?: boolean,
    public bathrooms?: number,
    public bedrooms?: number,
    public branding_name?: string,
    public region_id?: number,
    public city_id?: number,
    public district_id?: number,
    public established_date?: string,
    public location?: any,
    public region_obj?: Region,
    public city_obj?: City,
    public neighborhood?: string,
    public source?: string,
    public status?: MarketingRentalUnitStatus,
    public unit_age?: number,
    public unit_type?: string,
    public views_count?: number,
    public media_images?: Media[],
    public marketing_unit_detail?: MarketingUnitDetail,
    public region_text?: string,
    public city_text?: string,
    public publish_date?: string,
    public is_favorite?: boolean,
    public type?: string,
    public resource_type?: string,
    public resource_id?: number,
    public nhc_related?: boolean
  ) {}

  get imageGallery(): ImageSliderDataInterface[] {
    if (!this.media_images || this.media_images.length === 0) {
      return [] as ImageSliderDataInterface[];
    }
    return this.media_images?.map((image) => {
      return {
        sourceUrl: image?.file_url,
        thumbUrl: image?.file_url,
        originUrl: image?.file_url,
      } as ImageSliderDataInterface;
    });
  }

  get detailGallery(): ImageSliderDataInterface[] {
    if (
      !this.marketing_unit_detail ||
      !this.marketing_unit_detail.marketing_info ||
      !this.marketing_unit_detail.marketing_info.medias
    ) {
      return [];
    }

    return this.marketing_unit_detail.marketing_info.medias.map((image) => {
      return {
        sourceUrl: image?.file_url,
        thumbUrl: image?.file_url,
        originUrl: image?.file_url,
      } as ImageSliderDataInterface;
    });
  }

  get detailUrl() {
    return `/marketing-rental-unit/${this.id}`;
  }

  get isNegotiable(): boolean {
    return !!this.marketing_unit_detail?.marketing_info?.price_is_negotiable;
  }

  get arrangeUnitInfo() {
    const unitInformation = [
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.UNIT_TYPE',
        value: this.getKeyTranslation('PROJECT_CARD.RENT_UNIT_TYPES', this.marketing_unit_detail?.unit_info?.unit_type),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.UNIT_USE',
        value: this.getKeyTranslation(
          'PROJECT_CARD.RENT_UNIT_TYPES',
          this.marketing_unit_detail?.unit_info?.unit_usage
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.FLOOR_NUMBER',
        value: this.marketing_unit_detail?.unit_info?.floor_number,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.ESTIMATED_DATE',
        value: this.marketing_unit_detail?.unit_info?.established_date,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.UNIT_DIRECTION',
        value: this.getKeyTranslation(
          'MARKETING_RENTAL_UNIT.MODEL.UNIT_DIRECTION',
          this.marketing_unit_detail?.unit_info?.unit_direction
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.MEZZANINE',
        value: this.getKeyTranslation(
          'COMMON',
          this.marketing_unit_detail?.unit_info?.include_mezzanine ? 'YES' : 'NO'
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.FURNISHED',
        value: this.getKeyTranslation('COMMON', this.marketing_unit_detail?.unit_info?.is_furnished ? 'YES' : 'NO'),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.FURNISH_TYPE',
        value: this.getKeyTranslation(
          'MARKETING_RENTAL_UNIT.MODEL.FURNISH_TYPE',
          this.marketing_unit_detail?.unit_info?.furnish_type
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.UNIT_FINISHING',
        value: this.getKeyTranslation(
          'MARKETING_RENTAL_UNIT.MODEL.UNIT_FINISHING',
          this.marketing_unit_detail?.unit_info?.unit_finishing
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.NUMBER_OF_ROOMS',
        value: this.marketing_unit_detail?.unit_info?.number_of_rooms,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.BEDROOMS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.bedrooms,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.BATHROOMS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.bathrooms_full,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.HALLS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.halls,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.MAJLES',
        value: this.marketing_unit_detail?.unit_info?.amenities?.majles,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.KITCHEN',
        value: this.marketing_unit_detail?.unit_info?.amenities?.kitchen,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.KITCHEN_CABINETS',
        value: this.getKeyTranslation(
          'COMMON',
          this.marketing_unit_detail?.unit_info?.amenities?.is_cabinet_installed ? 'YES' : 'NO'
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.STOREROOM',
        value: this.marketing_unit_detail?.unit_info?.amenities?.storeroom,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.MAID_ROOM',
        value: this.marketing_unit_detail?.unit_info?.amenities?.maid_room,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.BACKYARDS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.backyard,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.CENTRAL_AC',
        value: this.marketing_unit_detail?.unit_info?.amenities?.central_ac,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.SPLIT_UNITS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.split_unit,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.DESERT_COOLERS',
        value: this.marketing_unit_detail?.unit_info?.amenities?.desert_cooler,
      },
    ];
    return this.handleArrangeTables(unitInformation);
  }

  get arrangePropertyInfo(): { [key: number]: TableInformationDataInterface[] } {
    const propertyInfo = [
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.PROPERTY_TYPE',
        value: this.getKeyTranslation(
          'PROJECT_CARD.RENT_UNIT_TYPES',
          this.marketing_unit_detail?.property_info?.property_type
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.PROPERTY_USAGE',
        value: this.getKeyTranslation(
          'PROJECT_CARD.RENT_UNIT_TYPES',
          this.marketing_unit_detail?.property_info?.property_usage?.toUpperCase()
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.BUILD_DATE',
        value: this.marketing_unit_detail?.property_info?.build_date,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.TOTAL_FLOOR',
        value: this.marketing_unit_detail?.property_info?.total_floors,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.UNITS_PER_FLOOR',
        value: this.marketing_unit_detail?.property_info?.units_per_floor,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.PART_OF_COMPOUND',
        value: this.getKeyTranslation(
          'COMMON',
          this.marketing_unit_detail?.property_info?.is_compound_property_part ? 'YES' : 'NO'
        ),
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.PARKING_SPACES',
        value: this.marketing_unit_detail?.property_info?.associated_facilities?.parking_spaces,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.ELEVATOR',
        value: this.marketing_unit_detail?.property_info?.associated_facilities?.elevators,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.SECURE_ENTRY',
        value: this.marketing_unit_detail?.property_info?.associated_facilities?.security_entries,
      },
      {
        key: 'MARKETING_RENTAL_UNIT.TABLE.GYM',
        value: this.marketing_unit_detail?.property_info?.associated_facilities?.gyms_fitness_centers,
      },
    ];
    return this.handleArrangeTables(propertyInfo);
  }

  handleArrangeTables(dataTable: TableInformationDataInterface[]): { [key: number]: TableInformationDataInterface[] } {
    let index = 0;
    let maxColumn = 3;
    return dataTable.reduce(
      (previousValue, currentValue) => {
        if (currentValue?.value) {
          previousValue[index].push(currentValue);
          index = index === maxColumn - 1 ? 0 : index + 1;
          return previousValue;
        }
        return previousValue;
      },
      { 0: [], 1: [], 2: [] } as { [key: number]: TableInformationDataInterface[] }
    );
  }

  get markerLocations(): SakaniMarkerInterface[] {
    return [
      {
        id: '1',
        lngLat: [
          this.marketing_unit_detail?.marketing_info?.longitude || 0,
          this.marketing_unit_detail?.marketing_info?.latitude || 0,
        ],
        address: this.marketing_unit_detail?.marketing_info?.branding_name,
      },
    ] as SakaniMarkerInterface[];
  }

  get lngLat(): LngLatLike | undefined {
    const marketingInfo = this.marketing_unit_detail?.marketing_info;
    if (marketingInfo && marketingInfo?.longitude && marketingInfo?.latitude) {
      return [marketingInfo?.longitude, marketingInfo?.latitude];
    }
    return undefined;
  }

  getKeyTranslation(parentKey: string | undefined, value: any): any {
    return value && parentKey ? getMappedKeyValue(parentKey, value) : undefined;
  }

  get latLon(): CoordinateInterface {
    return {
      lon: this.marketing_unit_detail?.marketing_info?.longitude || 0,
      lat: this.marketing_unit_detail?.marketing_info?.latitude || 0,
    };
  }
}
