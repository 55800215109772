import { Project } from './project';

export class MarketplaceUnitModel extends Project {
  constructor() {
    super();
  }

  get projectUrl() {
    if (this.isPrivateProject && this.isLandPrivateProject) {
      return '/private-projects';
    } else {
      if (this.isOffPlan) {
        return '/offplan-projects';
      } else if (this.isReadyMade) {
        return '/readymade-projects';
      } else if (this.isAdvanceSCLand) {
        return '/asc-projects';
      } else if (this.isLand) {
        return '/land-projects';
      } else {
        return '#';
      }
    }
  }
}
