import {
  BookingPaymentOption,
  BookingStatus,
  EPaymentStatus,
  InvoiceOrderType,
  InvoiceStatus,
  ProjectTypes,
  SalesContractStatus,
} from '@app/enums';
import { AnnexStatus } from '@app/enums/annex-status.enum';
import { BookingRequest, Invoice, ProjectBeneficiaryWaitingList } from '@app/models';
import { JsonApi } from '.';
import { ActiveSubsidyContract } from './active-subsidy-contract';
import { Annex } from './annex';
import { BookingAdditionalService } from './booking-additional-service';
import { BuildingPermit } from './building-permit';
import { BuildingPermitLicense } from './building-permit_license';
import { ConstructionCert } from './construction_certs';
import { Conveyance } from './conveyance';
import { IneligibleBeneficiaryContract } from './ineligible-beneficiary-contract';
import { TextualMedia } from './media';
import { OffplanHousingSubsidyContract } from './offplan-housing-subsidy-contract';
import { PriceQuotation } from './price-quotation';
import { Project } from './project';

export class Booking {
  relationships: any = {
    active_price_quotation: {
      rel: 'has_one',
      model: PriceQuotation,
    },
    project: {
      rel: 'has_one',
      model: Project,
    },
    invoice: {
      rel: 'has_one',
      model: Invoice,
    },
    active_invoice_for_extending_price_quotation: {
      rel: 'has_one',
      model: Invoice,
    },
    latest_building_permit: {
      rel: 'has_one',
      model: BuildingPermit,
    },
    latest_construction_cert: {
      rel: 'has_one',
      model: ConstructionCert,
    },
    latest_conveyance: {
      rel: 'has_one',
      model: Conveyance,
    },
    latest_booking_request: {
      rel: 'has_one',
      model: BookingRequest,
    },
    latest_construction_cert_extension: {
      rel: 'has_one',
      model: BookingRequest,
    },
    project_beneficiary_waiting_list: {
      rel: 'has_one',
      model: ProjectBeneficiaryWaitingList,
    },
    latest_building_permit_license: {
      rel: 'has_one',
      model: BuildingPermitLicense,
    },
    active_subsidy_contract: {
      rel: 'has_one',
      model: ActiveSubsidyContract,
    },
    offplan_housing_subsidy_contract: {
      rel: 'has_one',
      model: OffplanHousingSubsidyContract,
    },
    ineligible_beneficiary_contract: {
      rel: 'has_one',
      model: IneligibleBeneficiaryContract,
    },
  };

  active_price_quotation?: PriceQuotation;
  project?: Project;
  invoice?: Invoice;
  active_invoice_for_extending_price_quotation?: Invoice;
  latest_building_permit?: BuildingPermit;
  latest_construction_cert?: ConstructionCert;
  latest_conveyance?: Conveyance;
  latest_booking_request?: BookingRequest;
  latest_construction_cert_extension?: BookingRequest;
  project_beneficiary_waiting_list?: ProjectBeneficiaryWaitingList;
  latest_building_permit_license?: BuildingPermitLicense;
  active_subsidy_contract?: ActiveSubsidyContract;
  offplan_housing_subsidy_contract?: OffplanHousingSubsidyContract;
  ineligible_beneficiary_contract?: IneligibleBeneficiaryContract;

  constructor(
    public id?: number,
    public beneficiary_national_id_number?: string,
    public status?: BookingStatus,
    public unit_code?: number,
    public unit_type?: string,
    public construction_status?: string,
    public unit_size?: number,
    public bathroom_count?: number,
    public bedroom_count?: number,
    public booking_date?: Date,
    public finalized_date?: Date,
    public allocated_date?: Date,
    public cancelled_date?: Date,
    public price_quotation_date?: Date,
    public price?: string,
    public moh_price?: number,
    public bank_cr_number?: string,
    public bank_name?: string,

    public project_id?: number,
    public project_code?: string,
    public region_id?: string | number,
    public city_id?: string | number,
    public cancel_reason?: string,
    public signed_form?: string,
    public confirmed_date?: Date,
    public signed_form_uploaded_by?: string,
    public booking_additional_service?: BookingAdditionalService,
    public editable?: boolean,
    public booking_system_bcp_id?: number,
    public project_type?: string,
    public project_is_financeable?: boolean,
    public building_permit_expired_date?: Date,
    public construction_expired_date?: Date,
    public final_deed_number?: string,
    public requested_cancel_at?: string,
    public building_permit_status?: string,
    public construction_cert_status?: string,
    public booking_request_status?: string,
    public final_deed_document?: string,
    public building_permit_deactivated_at?: string,
    public construction_cert_deactivated_at?: string,
    public beneficiary_id_issuance_date?: Date,
    public beneficiary_id_issuance_place?: string,
    public beneficiary_address?: string,
    public wasel_city?: string,
    public booked_by?: string,
    public signing_expiration_date?: string,
    public booking_source?: string,
    public promoting_vat?: boolean,
    public sadad_invoice_number?: string,
    public sadad_invoice_expiration_at?: Date,
    public sadad_order_requested_at?: Date,
    public payment_status?: string,
    public unit_land_type?: string,
    public order_number?: string,
    public can_request_cancel_on_land?: boolean,
    public developer_cr_number?: string,
    public cancelled_by_user?: any,
    public cancelled_by_platform?: string,
    public cancelled_by?: string,
    public subsidy_type?: string,
    public subsidy_amount?: number,
    public max_subsidy_amount?: number,
    public base_land_price?: number,
    public apartment_number?: string,
    public block_number?: string,
    public building_number?: string,
    public floor?: number,
    public land_number?: string,
    public project_name?: string,
    public project_media_name?: string,
    public developer_name?: string,
    public project_phase?: string,
    public beneficiary?: any,
    public plan_number?: string,
    public project_media?: any,
    public project_subdivision_request_number?: string,
    public has_active_financial_application?: boolean,
    public subsidy_contract_number?: boolean,
    public unit_id?: number,
    public unit_model?: string,
    public land_type?: string,
    public created_at?: Date,
    public updated_at?: Date,
    public booking_payment_option?: BookingPaymentOption,
    public lending_type?: string,
    public waiting_list_status?: string,
    public can_request_online_lending?: boolean,
    public permit_manually_uploadable?: boolean,
    public azm_encrypted_invoice_number?: string,
    public for_ineligible_user?: boolean,
    public sale_contract_status?: SalesContractStatus,
    public sale_contract_reject_reason?: string,
    public sale_contract_file_url?: string,
    public project_sale_contract_id?: number,
    public active_financing_guarantee_journey?: boolean,
    public has_booking_fee?: boolean
  ) {}

  get isPremiumLand() {
    return this.project?.isLand && this.land_type === 'non_free_premium';
  }

  get activePayment() {
    return (
      !!this.sadad_invoice_number &&
      (this.payment_status === EPaymentStatus.NotPaid || this.payment_status === EPaymentStatus.Pending)
    );
  }

  get canCancelBooking() {
    if (!this.can_request_cancel_on_land) {
      return false;
    }
    if (this.requested_cancel_at) {
      return false;
    }
    if (this.status === BookingStatus.CANCELLED || this.status === BookingStatus.COMPLETED) {
      return false;
    }

    if (this.status === BookingStatus.INITIAL && this.isPremiumLand) {
      return false;
    }

    if (this.has_active_financial_application) {
      return false;
    }

    if (this.project?.isAdvancedSelfConstruction && this.activePayment) {
      return false;
    }

    if (this.invoice && this.invoice.status === 'pending' && this.invoice.order_type === 'offplan_booking_fee') {
      return false;
    }

    return true;
  }

  get textual() {
    if (this.project_media && this.project_media.textual && this.project_media.textual.attributes) {
      return JsonApi.buildFromObject(TextualMedia, this.project_media.textual.attributes.meta);
    }
    return undefined;
  }

  get isCancelledBooking(): boolean {
    if (!this.status) {
      return false;
    }
    return this.status && this.status === BookingStatus.CANCELLED;
  }

  get isActiveBooking(): boolean {
    if (!this.status) {
      return false;
    }
    return [
      BookingStatus.INITIAL,
      BookingStatus.PRICE_QUOTATION,
      BookingStatus.SELECTED,
      BookingStatus.CONTRACTED,
      BookingStatus.BUILDING_PERMIT_PHASE,
      BookingStatus.CONSTRUCTION_PHASE,
      BookingStatus.BOOKED,
    ].includes(this.status);
  }

  get isCompletedBooking(): boolean {
    if (!this.status) {
      return false;
    }
    return [
      BookingStatus.FINAL,
      BookingStatus.ALLOCATED,
      BookingStatus.CONFIRMED,
      BookingStatus.COMPLETED,
      BookingStatus.SOLD,
      BookingStatus.CONFIRMED_BY_DEVELOPER,
    ].includes(this.status);
  }

  get hadRequestedCancel(): boolean {
    return this.requested_cancel_at! ? true : false;
  }

  get isOffPlan() {
    return this.project?.isOffPlan;
  }

  get isLand() {
    return (
      this.project_type === ProjectTypes.LANDS_MOH_LAND ||
      this.project_type === ProjectTypes.LANDS_PRIVATE_LAND ||
      this.project_type === ProjectTypes.OFFPLAN_LAND_PRIVATE_LAND ||
      this.project_type === ProjectTypes.ADVANCED_SELF_CONSTRUCTION_LAND
    );
  }

  get pricesInfo() {
    if (this.for_ineligible_user && this.isOffPlan) {
      return {
        label: 'MARKETPLACE.SINGLE_UNIT.DETAILS.MARKET_PRICE',
        price: +(this.price || 0),
      };
    }

    return {
      label: 'UNIT_DETAIL.UNIT_DESCRIPTION.UNIT_PRICE',
      price: +(this.moh_price || 0),
    };
  }

  get isOffplanPrivateLand() {
    return this.project_type === ProjectTypes.OFFPLAN_PRIVATE_LAND;
  }

  get hasSalesContract() {
    return this.sale_contract_status !== SalesContractStatus.INITIAL;
  }

  get isOffplanMohLand() {
    return this.project_type === ProjectTypes.OFFPLAN_MOH_LAND;
  }

  get isAdvancedSelfConstruction() {
    return this.project_type === ProjectTypes.ADVANCED_SELF_CONSTRUCTION_LAND;
  }

  get isNotAppliedAutoCancelBooking(): boolean {
    return (
      this.isOffplanPrivateLand ||
      !!(this.isOffplanMohLand && this.for_ineligible_user) ||
      !!(this.isOffplanMohLand && !this.active_price_quotation?.allow_auto_cancel)
    );
  }

  get isPendingInvoice() {
    return (
      this.invoice?.status == InvoiceStatus.PENDING ||
      this.active_invoice_for_extending_price_quotation?.status == InvoiceStatus.PENDING
    );
  }

  get isSignedSaleContract() {
    return this.sale_contract_status === SalesContractStatus.SIGNED;
  }

  get hasInvoice(): boolean {
    return this.invoice && this.invoice?.status! ? true : false;
  }

  get bookingFeeIsOnPending(): boolean {
    if (
      this.has_booking_fee &&
      this.hasInvoice &&
      this.invoice?.status === InvoiceStatus.PENDING &&
      this.invoice?.order_type === InvoiceOrderType.OFFPLAN_BOOKING_FEE
    ) {
      return true;
    }

    if (this.has_booking_fee && !this.hasInvoice) {
      return true;
    }
    return false;
  }
}

export class MyBooking extends Booking {
  constructor(
    public id?: number,
    public beneficiary_national_id_number?: string,
    public status?: BookingStatus,
    public unit_code?: number,
    public unit_type?: string,
    public construction_status?: string,
    public unit_size?: number,
    public bathroom_count?: number,
    public bedroom_count?: number,
    public booking_date?: Date,
    public finalized_date?: Date,
    public allocated_date?: Date,
    public cancelled_date?: Date,
    public price_quotation_date?: Date,
    public price?: string,
    public moh_price?: number,
    public bank_cr_number?: string,
    public bank_name?: string,
    public project_id?: number,
    public project_code?: string,
    public region_id?: string | number,
    public city_id?: string | number,
    public cancel_reason?: string,
    public signed_form?: string,
    public confirmed_date?: Date,
    public signed_form_uploaded_by?: string,
    public booking_additional_service?: BookingAdditionalService,
    public editable?: boolean,
    public booking_system_bcp_id?: number,
    public project_type?: string,
    public project_is_financeable?: boolean,
    public building_permit_expired_date?: Date,
    public construction_expired_date?: Date,
    public final_deed_number?: string,
    public requested_cancel_at?: string,
    public building_permit_status?: string,
    public construction_cert_status?: string,
    public booking_request_status?: string,
    public final_deed_document?: string,
    public building_permit_deactivated_at?: string,
    public construction_cert_deactivated_at?: string,
    public beneficiary_id_issuance_date?: Date,
    public beneficiary_id_issuance_place?: string,
    public beneficiary_address?: string,
    public wasel_city?: string,
    public booked_by?: string,
    public signing_expiration_date?: string,
    public booking_source?: string,
    public promoting_vat?: boolean,
    public sadad_invoice_number?: string,
    public sadad_invoice_expiration_at?: Date,
    public sadad_order_requested_at?: Date,
    public payment_status?: string,
    public unit_land_type?: string,
    public order_number?: string,
    public can_request_cancel_on_land?: boolean,
    public developer_cr_number?: string,
    public cancelled_by_user?: any,
    public cancelled_by_platform?: string,
    public cancelled_by?: string,
    public subsidy_type?: string,
    public subsidy_amount?: number,
    public max_subsidy_amount?: number,
    public base_land_price?: number,
    public apartment_number?: string,
    public block_number?: string,
    public building_number?: string,
    public floor?: number,
    public land_number?: string,
    public project_name?: string,
    public project_media_name?: string,
    public developer_name?: string,
    public project_phase?: string,
    public beneficiary?: any,
    public plan_number?: string,
    public project_media?: any,
    public project_subdivision_request_number?: string,
    public has_active_financial_application?: boolean,
    public subsidy_contract_number?: boolean,
    public unit_id?: number,
    public unit_model?: string,
    public land_type?: string,
    public created_at?: Date,
    public updated_at?: Date,
    public booking_payment_option?: BookingPaymentOption,
    public lending_type?: string,
    public resource_id?: number,
    public resource_type?: string,
    public waiting_list_status?: string,
    public can_request_online_lending?: boolean
  ) {
    super(
      id,
      beneficiary_national_id_number,
      status,
      unit_code,
      unit_type,
      construction_status,
      unit_size,
      bathroom_count,
      bedroom_count,
      booking_date,
      finalized_date,
      allocated_date,
      cancelled_date,
      price_quotation_date,
      price,
      moh_price,
      bank_cr_number,
      bank_name,
      project_id,
      project_code,
      region_id,
      city_id,
      cancel_reason,
      signed_form,
      confirmed_date,
      signed_form_uploaded_by,
      booking_additional_service,
      editable,
      booking_system_bcp_id,
      project_type,
      project_is_financeable,
      building_permit_expired_date,
      construction_expired_date,
      final_deed_number,
      requested_cancel_at,
      building_permit_status,
      construction_cert_status,
      booking_request_status,
      final_deed_document,
      building_permit_deactivated_at,
      construction_cert_deactivated_at,
      beneficiary_id_issuance_date,
      beneficiary_id_issuance_place,
      beneficiary_address,
      wasel_city,
      booked_by,
      signing_expiration_date,
      booking_source,
      promoting_vat,
      sadad_invoice_number,
      sadad_invoice_expiration_at,
      sadad_order_requested_at,
      payment_status,
      unit_land_type,
      order_number,
      can_request_cancel_on_land,
      developer_cr_number,
      cancelled_by_user,
      cancelled_by_platform,
      cancelled_by,
      subsidy_type,
      subsidy_amount,
      max_subsidy_amount,
      base_land_price,
      apartment_number,
      block_number,
      building_number,
      floor,
      land_number,
      project_name,
      project_media_name,
      developer_name,
      project_phase,
      beneficiary,
      plan_number,
      project_media,
      project_subdivision_request_number,
      has_active_financial_application,
      subsidy_contract_number,
      unit_id,
      unit_model,
      land_type,
      created_at,
      updated_at,
      booking_payment_option,
      lending_type,
      waiting_list_status,
      can_request_online_lending
    );
  }
}
