import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { SakaniModalType } from '@app/enums';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class SakaniModalService {
  constructor(private ngbModal: NgbModal, private componentFactoryResolver: ComponentFactoryResolver) {}

  private configModal(data: any) {
    let centered = true;
    if (typeof data?.centered !== 'undefined') centered = data?.centered;
    const config = {
      centered: centered,
      animation: data?.animation || true,
      ariaDescribedBy: data?.ariaDescribedBy,
      ariaLabelledBy: data?.ariaLabelledBy,
      backdrop: data?.backdrop,
      backdropClass: data?.backdropClass || 'customize-modal-backdrop',
      beforeDismiss: data?.beforeDismiss,
      container: data?.container,
      injector: data?.injector,
      keyboard: data?.keyboard,
      scrollable: data?.scrollable,
      size: data?.size,
      windowClass: data?.windowClass,
      modalDialogClass: data?.modalDialogClass,
    };
    return config;
  }

  dismissAll() {
    this.ngbModal.dismissAll();
  }

  hasOpenModals() {
    return this.ngbModal.hasOpenModals();
  }

  showTemplateModalComponent(theComponent: any, data: any) {
    this.componentFactoryResolver.resolveComponentFactory(theComponent);

    const modalRef = this.ngbModal.open(theComponent, this.configModal(data));

    modalRef.componentInstance.type = SakaniModalType.TEMPLATE;
    modalRef.componentInstance.template = data.template;
    modalRef.componentInstance.headerText = data.headerText;
    modalRef.componentInstance.headerParams = data.headerParams;
    modalRef.componentInstance.showCloseIcon = data.showCloseIcon;
    modalRef.componentInstance.secondaryHeaderText = data.secondaryHeaderText;

    if (data.templateClass) {
      modalRef.componentInstance.templateClass = data.templateClass;
    }

    if (data.headerClass) {
      modalRef.componentInstance.headerClass = data.headerClass;
    }

    if (data.closeIconClass) {
      modalRef.componentInstance.closeIconClass = data.closeIconClass;
    }

    return modalRef;
  }

  showMessageModal(theComponent: any, data: any): NgbModalRef {
    this.componentFactoryResolver.resolveComponentFactory(theComponent);

    if (data.v5Template) {
      data['windowClass'] = 'bottom-modal v5 fade';
      data['backdrop'] = 'static';
    }

    const modalRef = this.ngbModal.open(theComponent, this.configModal(data));
    modalRef.componentInstance.type = SakaniModalType.MESSAGE;
    modalRef.componentInstance.success = data.success;
    modalRef.componentInstance.headerText = data.headerText;
    modalRef.componentInstance.headerParams = data.headerParams;
    modalRef.componentInstance.message = data.message;
    modalRef.componentInstance.confirmButtonText = data.confirmButtonText;
    modalRef.componentInstance.closeButtonText = data.closeButtonText;

    modalRef.componentInstance.v5Template = data.v5Template;
    modalRef.componentInstance.prefixHeaderIconUrl = data.prefixHeaderIconUrl ?? '';
    modalRef.componentInstance.headerIconClass = data.headerIconClass;
    modalRef.componentInstance.showHeaderIcon = data.showHeaderIcon;
    modalRef.componentInstance.showCloseIconInMessage = data.showCloseIconInMessage;

    if (data.confirmButtonClasses) {
      modalRef.componentInstance.confirmButtonClasses = data.confirmButtonClasses;
    }

    if (data.templateClass) {
      modalRef.componentInstance.templateClass = data.templateClass;
    }
    modalRef.componentInstance.isCenterAlignment = data.isCenterAlignment;
    if (data.headerClass) {
      modalRef.componentInstance.headerClass = data.headerClass;
    }
    if (data.closeIconClass) {
      modalRef.componentInstance.closeIconClass = data.closeIconClass;
    }
    return modalRef;
  }

  showComponentModal(theComponent: any, config?: any) {
    return this.ngbModal.open(theComponent, this.configModal(config));
  }
}
